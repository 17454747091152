<template>
  <div class="more">
    <h1 class="text-gray-50 text-center">Coming Soon</h1>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'more',
  components: {
  }
}
</script>
